/* BioData.css */

.bio-data {
    display: flex;
    flex-direction: column;
    padding: 3%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background: rgb(34, 193, 195);
    background: linear-gradient(
    135deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );
}

.biodata-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1000px;
    margin: 0px auto;
    padding: 35px;
    background: linear-gradient(to right, #e6e9f0 0%, #eef1f5 100%);
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.12);
}

.biodata-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    margin-top: 0px;
    color: #2c3e50;
    font-size: 28px;
}

.biodata-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1000px;
    margin: 0px auto;
    padding: 15px;
    background: linear-gradient(to right, #e6e9f0 0%, #eef1f5 100%);
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.12);
}

.biodata-photo1 {
    flex: 0 0 30%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    margin: 8px;
}

.biodata-photo2 {
    flex: 0 0 30%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    margin: 8px;
}

.biodata-photo img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
}

.biodata-info {
    flex: 1;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.biodata-info h1 {
    align-self: center;
    width: 100%;
    text-align: center;
    margin-top: 0;
    color: #2c3e50;
    font-size: 28px;
}

.family-background {
    /*margin-top: 20px;
    padding: 15px;
    background-color: #f5f5f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
}

.family-background h3 {
    margin-top: 10px;
    color: #2c3e50;
}

.family-background p {
    padding: 0 25px;
    margin-top: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .biodata-container {
        flex-direction: column;
        padding: 20px;
    }

    .biodata-photo, .biodata-info {
        width: 100%;
    }

    .biodata-info {
        padding-left: 0;
    }

    .biodata-info h1 {
        align-self: flex-start;
        text-align: left;
    }
}
